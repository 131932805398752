<template>
  <section id="Summary">
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <b-container>
      <div v-if="buttonTitles || buttonLinks" class="links">
        <a v-if="buttonTitles['0'] || buttonLinks['0']" v-bind:href="'https://usopen.vipfanportal.com/upload/' + buttonLinks['0']" download target="_blank">
          <img src="@/assets/icons/summary/icon_download.svg" />
          <p v-if="buttonTitles['0']">{{ buttonTitles['0'] }}</p>
          <p v-else>{{ buttonLinks['0'] }}</p>
        </a>
        <a v-if="buttonTitles['1'] || buttonLinks['1']" v-bind:href="'https://usopen.vipfanportal.com/upload/' + buttonLinks['1']" download target="_blank">
          <img src="@/assets/icons/summary/icon_download.svg" />
          <p v-if="buttonTitles['1']">{{ buttonTitles['1'] }}</p>
          <p v-else>{{ buttonLinks['1'] }}</p>
        </a>
        <a v-if="buttonTitles['2'] || buttonLinks['2']" v-bind:href="'https://usopen.vipfanportal.com/upload/' + buttonLinks['2']" download target="_blank">
          <img src="@/assets/icons/summary/icon_download.svg" />
          <p v-if="buttonTitles['2']">{{ buttonTitles['2'] }}</p>
          <p v-else>{{ buttonLinks['2'] }}</p>
        </a>
        <a v-if="buttonTitles['3'] || buttonLinks['3']" v-bind:href="'https://usopen.vipfanportal.com/upload/' + buttonLinks['3']" download target="_blank">
          <img src="@/assets/icons/summary/icon_download.svg" />
          <p v-if="buttonTitles['3']">{{ buttonTitles['3'] }}</p>
          <p v-else>{{ buttonLinks['3'] }}</p>
        </a>
      </div>
    </b-container>
    <div class="gradient"></div>
    <hr />
    <b-container>
      <div v-if="customData" class="summary">
        <div class="left">
          <h1 v-html="customData['largeMessage']"></h1>
          <a v-if="threeDLink" :href="threeDLink" target="_blank">
            <img src="@/assets/icons/summary/icon_3d-view.svg" />
            your 3d view
          </a>
          <div v-if="customPicture" class="left__picture" @click="modalShow = !modalShow">
            <div class="left__picture-overlay"></div>
            <div class="left__picture-icon">
              <img src="@/assets/icons/summary/icon_expand.svg" />
            </div>
            <img class="left__picture-img" :src="(customPicture)" alt="custom picture" />
          </div>
        </div>
        <div class="right">
          <div v-html="customData['copy']"></div>
          <h4>Thank you for your time today.</h4>
          <p>
            You can reach me at <a :href="'mailto:' + repInfo.email">{{ repInfo.email }}.</a>
          </p>
        </div>
      </div>
    </b-container>
    <hr />
    <b-modal id="modal-2" v-model="modalShow">
      <div class="modal__close" @click="modalShow = !modalShow">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
      </div>
      <div class="summary-modal">
        <img :src="(customPicture)" alt="custom picture" />
      </div>
    </b-modal>
  </section>
</template>

<script>
import HeroCarousel from '@/components/patterns/HeroCarousel'
import { getSummaryData } from "@/data/HeroCarousel/summaryData"
import axios from "axios"

  export default {
    name: "Summary",
    components: {
      HeroCarousel
    },
    data() {
      return {
        heroCarouselData: getSummaryData(),
        customData: null,
        buttonTitles: null,
        buttonLinks: null,
        repInfo: null,
        threeDLink: null,
        customPicture: null,
        modalShow: false
      }
    },
    mounted() {
      this.$store.watch((state) => {
        let pages = state.epitch.ePitch.pages;
        this.repInfo = state.epitch.ePitch.rep

        if (typeof pages !== "undefined") {
          pages.forEach(page => {
            if (page.id === "1000") {
              // this.customData = JSON.parse(page.copy)
              this.customData = typeof page.copy !== 'undefined' && Object.keys(page.copy).length ? JSON.parse(page.copy) : null;
              // this.buttonTitles = JSON.parse(page.attachmentTitle)
              this.buttonTitles = typeof page.attachmentTitle !== 'undefined' && Object.keys(page.attachmentTitle).length ? JSON.parse(page.attachmentTitle) : {};
              // this.buttonLinks = JSON.parse(page.attachment)
              this.buttonLinks = typeof page.attachment !== 'undefined' && Object.keys(page.attachment).length ? JSON.parse(page.attachment) : {};
              if(page.image) {
                this.customPicture = `https://usopen.vipfanportal.com/upload/${page.image}`
              }
            }
            if (page.id === "1001") {
              let info = JSON.parse(page.copy)
              this.threeDLink = info.threeDLink
            }
          })
        }
      })

      if(this.$store.state.epitch.ePitch.mode === "live"){
        const purl = this.$store.state.epitch.ePitch.purl
        const epitchUrl = this.$store.state.epitch.url
        axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
            method:'page',
            purl:purl,
            presentationId: window.presentationId,
            sessionDbId: window.sessionDbId,
            section: 1000
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #Summary {
    hr {
      width: 90%;
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 71px;
      width: 100%;
      @media (max-width: $lg) {
        height: auto;
        flex-wrap: wrap;
        padding: 10px 0;
      }
      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
        background: #ffffff;
        padding: 18px 52px 19px 53px;
        margin-right: 10px;
        border: none;
        border-bottom: 2px solid #418fde;
        outline: none;
        font-family: 'interstate', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 11px;
        color: #373737;
        letter-spacing: 0;
        text-transform: uppercase;
        text-decoration: none;
        box-shadow: 0 7px 14px 0 rgba(0,0,0,0.10);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        @media (max-width: $lg) {
          margin: 10px 10px;
          width: 45%;
        }
        &:hover {
          border-bottom: 2px solid #3c82ca;
        }
        &:last-child {
          margin-right: 0;
          @media (max-width: $lg) {
            margin-right: 10px;
          }
        }
        img {
          height: 13px;
          width: 11px;
          margin-right: 15px;
        }
      }
    }
    .summary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 100px 0;
      @media (max-width: $lg) {
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
      }
      .left {
        min-height: 100%;
        width: 47%;
        @media (max-width: $lg) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 40px;
        }
        h1 {
          font-family: 'interstate', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          color: #373737;
          letter-spacing: 0;
          line-height: 49px;
        }
        a {
          background: #418fde;
          border: none;
          border-radius: 3px;
          padding: 19px 60px;
          width: max-content;
          font-family: 'interstate', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          color: #ffffff;
          text-transform: uppercase;
          letter-spacing: 0;
          text-align: center;
          outline: none;
          display: flex;
          align-items: center;
          margin-top: 30px;
          text-decoration: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          @media (max-width: $lg) {
            width: 100%;
            justify-content: center;
          }
          &:hover {
            background: #3c82ca;
          }
          img {
            height: 21px;
            width: 21px;
            margin-right: 11.5px;
            outline: none;
          }
        }
        &__picture {
          position: relative;
          margin-top: 37.5px;
          border-radius: 3px;
          overflow: hidden;
          cursor: pointer;
          @media (max-width: $lg) {
            width: 100%;
          }
          &-img {
            @media (max-width: $lg) {
              width: 100%;
            }
          }
          &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, rgba(0,40,140,0.10) 0%, rgba(6,27,81,0.70) 99%);
            transition: background-image 0.2s ease-in-out;
            &:hover {
              background-image: linear-gradient(180deg, rgba(0,40,140,0.30) 0%, rgba(6,27,81,0.70) 99%);
            }
          }
          &-icon {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 58px;
            width: 58px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        min-height: 100%;
        width: 47%;
        font-family: 'interstate', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #7f7f77;
        letter-spacing: 0;
        line-height: 24px;
        @media (max-width: $lg) {
          width: 100%;
        }
        h4 {
          font-family: 'interstate', sans-serif;
          font-style: normal;
          font-weight: 700px;
          font-size: 14px;
          letter-spacing: 0;
          color: #000000;
          line-height: 24px;
          margin-top: 15px;
        }
        a {
          font-family: 'interstate', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 24px;
          color: #418fde;
        }
      }
    }
  }
</style>

<style lang="scss">
  #modal-2 {
    .summary-modal {
      position: relative;
      height: min-content;
      img {
        // height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
</style>