export const getSummaryData = () => {
    return [
        {
            carouselImages: [
                'CenterCourtClub/Carousel/ccc_gallery_0.jpg',
                'CenterCourtClub/Carousel/ccc_gallery_1.jpg',
                'CenterCourtClub/Carousel/ccc_gallery_2.jpg'
            ],
            title: 'Summary'
        }
    ]
}